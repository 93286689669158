import React from "react";
import { graphql } from "gatsby";

import PublicSymbolPage from "../../components/public-symbol-page";

export const query = graphql`
  query ExplicitSymbolsPageQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      description: publicSymbolsExplicitDescription
    }
    symbols: allSanitySymbol(
      filter: { category: { eq: "explicit" }, hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        category
        slug {
          current
        }
        mainImage {
          ...SanityImage
          alt
          caption
        }
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const ExplicitSymbolsPage = ({ data = {} }) => (
  <PublicSymbolPage title="Explicit Hate" data={data} />
);

export default ExplicitSymbolsPage;
